import {createTheme} from "@mui/material/styles";
import { baseTheme } from "./base_theme";
import {STYLES_DARK, STYLES_LIGHT} from "./theme_settings";

const themeDark = createTheme(baseTheme,{
    typography:{
        h1: {
            fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
            fontSize: '4rem',
            fontWeight: 'bold',
            color: STYLES_DARK.PRIMARY_COLOR,
            [baseTheme.breakpoints.up('xs')]: {
                fontSize: '3rem', // Smaller font size for h1 on small screens
            },[baseTheme.breakpoints.up('sm')]: {
                fontSize: '3.5rem', // Smaller font size for h1 on small screens
            },[baseTheme.breakpoints.up('md')]: {
                fontSize: '4rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '4.5rem', // Larger font size for h1 on medium and larger screens
            },
            [baseTheme.breakpoints.up('xl')]: {
                fontSize: '5rem', // Larger font size for h1 on medium and larger screens
            },
        },
        h2: {
            fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
            fontSize: '3.5rem',
            color: STYLES_DARK.PRIMARY_COLOR,
            [baseTheme.breakpoints.up('xs')]: {
                fontSize: '1.8rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '2.5rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('md')]: {
                fontSize: '3rem', // Larger font size for h1 on medium and larger screens
            },[baseTheme.breakpoints.up('lg')]: {
                fontSize: '3.5rem', // Larger font size for h1 on medium and larger screens
            },[baseTheme.breakpoints.up('xl')]: {
                fontSize: '4rem', // Larger font size for h1 on medium and larger screens
            },
            fontWeight: 'bold',
        },
        h3: {
            fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
            fontSize: '3rem',
            fontWeight: 'bold',
            color: STYLES_DARK.PRIMARY_COLOR,
            [baseTheme.breakpoints.up('xs')]: {
                fontSize: '1.8rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '2rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('md')]: {
                fontSize: '2.5rem', // Larger font size for h1 on medium and larger screens
            },[baseTheme.breakpoints.up('lg')]: {
                fontSize: '3rem', // Larger font size for h1 on medium and larger screens
            },[baseTheme.breakpoints.up('xl')]: {
                fontSize: '3.5rem', // Larger font size for h1 on medium and larger screens
            },
        },
        h4: {
            fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: STYLES_DARK.PRIMARY_COLOR,
            [baseTheme.breakpoints.up('xs')]: {
                fontSize: '1.5rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.3rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('md')]: {
                fontSize: '1.5rem', // Larger font size for h1 on medium and larger screens
            },[baseTheme.breakpoints.up('lg')]: {
                fontSize: '2rem', // Larger font size for h1 on medium and larger screens
            },[baseTheme.breakpoints.up('xl')]: {
                fontSize: '2.5rem', // Larger font size for h1 on medium and larger screens
            },
        },
        h5: {
            fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
            fontSize: '2rem',
            fontWeight: 'bold',
            color: STYLES_DARK.PRIMARY_COLOR,
        },
        h6: {
            fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: STYLES_DARK.PRIMARY_COLOR,
        },
        body1: {
            fontFamily: STYLES_DARK.BODY_FONT_FAMILY,
            fontSize: '1rem',
            color: STYLES_DARK.PRIMARY_COLOR,
            [baseTheme.breakpoints.up('xs')]: {
                fontSize: '1rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.2rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('md')]: {
                fontSize: '1.2rem', // Larger font size for h1 on medium and larger screens
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '1.1rem', // Larger font size for h1 on medium and larger screens
            },
            [baseTheme.breakpoints.up('xl')]: {
                fontSize: '1.5rem', // Larger font size for h1 on medium and larger screens
            },
        },
        body2: {
            fontFamily: STYLES_DARK.BODY_FONT_FAMILY,
            fontSize: '1.5rem',
            color: STYLES_DARK.PRIMARY_COLOR,
            [baseTheme.breakpoints.up('xs')]: {
                fontSize: '1rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.2rem', // Smaller font size for h1 on small screens
            },
            [baseTheme.breakpoints.up('md')]: {
                fontSize: '1.3rem', // Larger font size for h1 on medium and larger screens
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '1.5rem', // Larger font size for h1 on medium and larger screens
            },
            [baseTheme.breakpoints.up('xl')]: {
                fontSize: '1.5rem', // Larger font size for h1 on medium and larger screens
            },

        },

    },
    palette: {
        mode: 'dark',
        primary: {
            main: STYLES_DARK.PRIMARY_COLOR,
        },
        secondary: {
            main: STYLES_DARK.SECONDARY_COLOR,
        },
        warning: {
            main: STYLES_DARK.WARNING_COLOR,
        },
        success: {
            main: STYLES_DARK.SUCCESS_COLOR,
        },
        info:{
            main:STYLES_DARK.INFO_COLOR
        },
        background:{
            default:STYLES_DARK.BACKGROUND_COLOR,
            paper:STYLES_DARK.BACKGROUND_COLOR
        }
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    // styles for the unselected state
                    color: '#ffffff', // or any color you wish to apply
                    fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
                    '&.Mui-selected': {
                        color: '#ffffff', // color when tab is selected
                        fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    fontFamily: STYLES_DARK.HEADING_FONT_FAMILY,
                    color: STYLES_DARK.BACKGROUND_COLOR,
                    '&:hover': {
                        backgroundColor: STYLES_DARK.BUTTON_PRIMARY_COLOR,
                    },
                },

            }
        },
        MuiCard: {
            styleOverrides: {

                root: {
                    backgroundColor: STYLES_DARK.WARNING_COLOR,
                }
            }
        },
        // MuiTextField: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: STYLES_DARK.BACKGROUND_COLOR,
        //         }
        //     }
        // },
        // MuiFormControl: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: STYLES_DARK.BACKGROUND_COLOR,
        //         }
        //     }
        // },
    }

});

export default themeDark;