import {
    Button,
    Grid,
    Hidden,
    Icon,
    IconButton, Link,
    Snackbar,
    SvgIcon,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import * as React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Instagram from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import WhatsApp from '@mui/icons-material/WhatsApp';
import TikTok from '../../../Assets/loon-icon.png'
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Image} from "@mui/icons-material";
interface FormData {
    name: string;
    email: string;
    message: string;
}
interface  ContactSectionProps{
    openSnack:boolean
    handleClose: (event: React.SyntheticEvent | Event, reason?: string)=>void
    handleClick:()=>void
}
const ContactSection = ({openSnack, handleClose,handleClick}:ContactSectionProps) => {
    const theme = useTheme();
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormData>();

    const onSubmit = async (data:FormData) => {
        try {
            const response = await fetch("https://formspree.io/f/xyyrybob", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                handleClick()
                reset()
                // console.log("Form successfully sent");
                // Handle success scenario
            } else {
                console.error("Formspree submission error:", response.statusText);
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };

const {t} = useTranslation();
    return (
      <>
          {/*<Hidden lgDown>*/}
              <Grid container item xs={12} id={'Contact'}  mt={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                  lg: 10,
                  xl: 10,

              }} mb={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                  lg: 5,
                  xl: 5,
              }} justifyContent={'center'}>
                  <Grid container item xs={12} lg={6} >
                      <Grid item xs={12} lg={10}>
                          <Typography
                              sx={{
                                  backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                                  '-webkit-background-clip': 'text',
                                  color: 'transparent',
                                  display: 'inline-block'
                              }}
                              variant="h2"
                          >
                              {t('label_contact_headline')}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={8} mt={4} mb={{
                            xs: 4,
                            sm: 4,
                            md: 4,
                            lg: 0,
                            xl: 0,


                      }}>
                          <Typography
                              variant="body1"
                          >
                              {t('label_contact_text')}</Typography>
                      </Grid>
                      <Grid container item xs={12} lg={8}  direction={'row'} alignItems={'center'}>
                          <LocalPhoneIcon  />
                          <Typography
                              marginLeft={'1rem'}
                              variant="h6"
                              width={'fit-content'}

                          >
                              <Link href="tel:+37122837006">22837006</Link>, <Link href="tel:+37126977520">26977520</Link>
                          </Typography>
                      </Grid>
                      <Grid container item xs={12} lg={8} mt={{
                            xs: 0,
                            sm: 0,
                            md: 0,
                            lg: 1,
                            xl: 1,

                      }} direction={'row'} alignItems={'center'}>
                          <EmailIcon  />
                          <Typography
                              marginLeft={'1rem'}
                              variant="body2"
                              width={'fit-content'}
                          >
                              picassomarketingagency@gmail.com
                          </Typography>
                      </Grid>
                      <Grid item xs={12} lg={8} mt={{
                            xs: 0,
                            sm: 0,
                            md: 0,
                            lg: 4,
                            xl: 4,

                      }} >
                          <IconButton href="https://wa.me/37122837006" target={'_blank'}>
                              <WhatsApp  sx={{color:theme.palette.primary.main}}   fontSize={'large'}/>
                          </IconButton>
                          <IconButton href="https://www.instagram.com/picasso.marketing/" target={'_blank'}>
                              <Instagram  sx={{color:theme.palette.primary.main}}   fontSize={'large'}/>
                          </IconButton>
                          <IconButton href="https://www.tiktok.com/@picasso.marketing?_t=8iAi0VjUw52&_r=1" target={'_blank'}>
                              {/*<Icon>{TikTok}</Icon>*/}
                              <SvgIcon sx={{color:theme.palette.primary.main}} fontSize={'large'}>
                                  <svg fill="#FFFFFF"  viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                              </SvgIcon>
                          </IconButton>
                      </Grid>
                  </Grid>

                  <Grid
                      component={'form'}
                      onSubmit={handleSubmit(onSubmit)}
                      container
                      item
                      mt={{
                            xs: 5,
                            sm: 5,
                            md: 5,
                            lg: 0,
                            xl: 0,
                      }}
                      xs={12} md={12} lg={6}
                      direction={'row'}
                      justifyContent={'center'}
                      height={'fit-content'}

                  >
                      {/* Name Field */}
                      <Grid item xs={12} md={8} lg={10} mb={'2rem'}>
                          <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>{t('label_form_name')}:</Typography>
                          <TextField
                              {...register("name", { required:t('label_validation_name') })}
                              type={'text'}
                              fullWidth
                              variant="outlined"
                              sx={{backgroundColor: theme.palette.secondary.main}}
                              error={errors.name ? true : false}
                              // helperText={<>{errors.name && errors.name.message}</>}
                          />
                          {errors.name && <Typography variant={'caption'}>{errors.name.message}</Typography>}
                      </Grid>

                      {/* Email Field */}
                      <Grid item  xs={12} md={8} lg={10} mb={'2rem'}>
                          <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>{t('label_form_email')}:</Typography>
                          <TextField
                              {...register("email", {
                                  required: t('label_validation_email'),
                                  pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: t('label_validation_wrong_email')
                                  }
                              })}
                              type={'email'}
                              fullWidth
                              variant="outlined"
                              sx={{backgroundColor: theme.palette.secondary.main, }}
                              error={errors.email ? true : false}
                          />
                          {errors.email && <Typography variant={'caption'}>{errors.email.message}</Typography>}
                      </Grid>

                       {/*Message Field*/}
                      <Grid item  xs={12} md={8} lg={10} mb={'2rem'}>
                          <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>{t('label_form_message')}</Typography>
                          <TextField
                              {...register("message", { required: t('label_validation_message') })}
                              fullWidth
                              // multiline
                              rows={8}
                              variant={'outlined'}
                              sx={{backgroundColor: theme.palette.secondary.main,  '& .MuiFormHelperText-root': {
                                      backgroundColor: 'transparent'
                                  }}}
                              error={errors.message ? true : false}
                              // helperText={<>{errors.message && errors.message.message}</>}

                          />
                          {errors.message && <Typography variant={'caption'}>{errors.message.message}</Typography>}
                      </Grid>

                      {/* Submit Button */}
                      <Grid item xs={12} md={8} lg={10} mb={'2rem'}>
                          <Button size={'large'} variant={'contained'} type={'submit'} color={'primary'} sx={{ borderRadius:'50px', backgroundImage: 'linear-gradient(90deg, #b16cea, #ffa14e)', display: 'inline' }}>
                              <Typography color={"primary"} variant={'h6'}>{t('label_from_submit')}</Typography>
                          </Button>
                      </Grid>
                  </Grid>
                  <Snackbar
                      open={openSnack}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      message="Note archived"
                      action={ <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleClose}
                      >
                          <CloseIcon fontSize="small" />
                      </IconButton>}
                  />
              </Grid>
      </>
    )
}
export default ContactSection