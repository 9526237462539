import {Box} from "@mui/material";
import ProfileSection from "../../Components/Organs/ProfileSection/ProfileSection";
import React from "react";
import ScrollToTopButton from "../../Components/Atoms/ScrollToTopButton/ScrollToTopButton";
import ContactSection from "../../Components/Organisms/ContactSection/ContactSection";
import FooterSection from "../../Components/Organisms/FooterSection/FooterSection";
import OurPojects from "../../Components/Organisms/OurProjects";
import ClientTestamonials from "../../Components/Organisms/ClientTestamonials";
import {companyMock, projectsMock, testimonialsMock} from "../../../Models/Models";
import OurServices from "../../Components/Organisms/OurServices/OurServices";
import CompaniesSection from "../../Components/Organisms/CompaniesSection/CompaniesSection";
import FaqSection from "../../Components/Organisms/FaqSection/FaqSection";
import {useApiContext} from "../../../Contexts/ApiContext";
import LineSpacer from "../../Components/Organs/LineSpacer/LineSpacer";
interface HomeProps {
handleClick:()=>void
    openSnack:boolean
    handleClose: (event: React.SyntheticEvent | Event, reason?: string)=>void
    handleOpenModal:()=>void
}
const Home = ({handleClick, handleClose, openSnack,handleOpenModal}:HomeProps) => {
const {testimonials,clients,portfolio} =useApiContext()
    return(
       <>
           <ProfileSection handleOpenModal={handleOpenModal}/>
           <LineSpacer />
           <Box  px={{
                xs: 2,
                sm: 2,
                md: 2,
                lg: 5,
                xl: 5,

           }} >
                <OurServices/>
               <CompaniesSection companies={clients}/>
                <OurPojects projects={portfolio}/>
                <ClientTestamonials testimonials={testimonials}/>
               <ContactSection handleClick={handleClick} openSnack={openSnack} handleClose={handleClose}/>
               <FaqSection />
               <FooterSection />
               <ScrollToTopButton />
           </Box>
       </>
    )
}
export default Home;