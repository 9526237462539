import {Grid, Hidden} from "@mui/material";
import GridPop from "../../Atoms/GridPop/GridPop";

import Pop5 from "../../../Assets/IMG_3273.jpeg";
import Pop6 from "../../../Assets/IMG_3140.jpg";

import Pop8 from "../../../Assets/IMG_3104.jpg";
import Pop7 from "../../../Assets/IMG_3272.jpeg"

import React from "react";

const PopBannerSmall = () => {

    return(
        <Grid height={'100%'}  container item xs={10} justifyContent={'center'}  spacing={2}>
                <Grid item xs={8} sm={4}><GridPop imgageUrl={Pop5} borderRadius={'200px'}/></Grid>
                <Grid item xs={4} sm={2}><GridPop imgageUrl={Pop6} borderRadius={'200px'}/></Grid>
                <Grid item xs={4} sm={4}><GridPop imgageUrl={Pop8} borderRadius={'200px'}/></Grid>
                <Grid item xs={8} sm={2}><GridPop imgageUrl={Pop7} borderRadius={'200px'}/></Grid>
        </Grid>
    )
}

export default PopBannerSmall;