import * as React from 'react';
import List from '@mui/material/List';
import ListItem from "./ListItem";
import { useTranslation } from 'react-i18next';



export default function FaqList() {
    const {t} = useTranslation();
    const faqs= [
        {
            question: t('label_faq_questions1'),
            answer: t('label_faq_answer1')
        },
        {
            question: t('label_faq_questions2'),
            answer: t('label_faq_answer2')
        },
        {
            question: t('label_faq_questions3'),
            answer: t('label_faq_answer3')
        },
        {
            question: t('label_faq_questions4'),
            answer: t('label_faq_answer4')
        },

    ]
    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >

            {  faqs.map((faq,index)=><ListItem key={index} question={faq.question} answer={faq.answer}/>)}
        </List>
    );
}