import {AppBar, Hidden, IconButton, Toolbar, Typography, useTheme} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
interface AppBarProps {
    handleDrawerOpen: ()=>void
}
const AppBarMob = ({ handleDrawerOpen}:AppBarProps) => {
    const theme = useTheme();
    return(
        <Hidden lgUp>
            <AppBar variant={'elevation'} sx={{bgcolor:theme.palette.background.paper}} position="sticky" >
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Picasso
                    </Typography>
                    <IconButton color={'primary'}  onClick={handleDrawerOpen}>
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Hidden>
    )
}
export default AppBarMob