import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {TextField, Button, Box, Typography, CircularProgress} from '@mui/material';
import {useApiContext} from "../../../Contexts/ApiContext";
import {ProjectBase, ProjectFormData, ProjectTranslation} from "../../../Models/Models";

const ProjectBaseForm: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<ProjectFormData>();
    const {addProject,isSubmitting,projectError,translationError} = useApiContext();
    const onSubmit: SubmitHandler<ProjectFormData> = (data:ProjectFormData,event) => {

        const projectBase:ProjectBase = {
            imageUrl:data.imageUrl,
            videoUrl:data.videoUrl,
            link:data.link,
            date: data.date,
        }
        const ru:ProjectTranslation = {
            title:data.ru.title,
            subtitle:data.ru.subtitle,
            description:data.ru.description,
            results:data.ru.results,
            language:'ru'
        }
        const lv:ProjectTranslation = {
            title:data.lv.title,
            subtitle:data.lv.subtitle,
            description:data.lv.description,
            results:data.lv.results,
            language:'lv'
        }
        addProject(projectBase,ru,lv)
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {(projectError || translationError) && <Typography variant={'h6'}>Try Again, something went wrong</Typography>}
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="text"
                label="Image Url"
                {...register("imageUrl", { required: true })}
                error={!!errors.imageUrl}
                helperText={errors.imageUrl ? "imageUrl is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="text"
                label="Video Url"
                {...register("videoUrl", { required: true })}
                error={!!errors.videoUrl}
                helperText={errors.videoUrl ? "videoUrl is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Link to media"
                type="text"
                {...register("link", { required: true})}
                error={!!errors.link}
                helperText={errors.link ? "link is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Datums"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...register("date", { required: true })}
                error={!!errors.date}
                helperText={errors.date ? "Date is required" : ""}
            />
            <Box display={'flex'} flexDirection={'row'}>
               <Box px={4}>
                   <Typography variant={'h6'}>LV</Typography>
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Virsraksts(LV)"
                       {...register("lv.title", { required: true })}
                       error={!!errors.lv?.title}
                       helperText={errors.lv?.title ? "title is required" : ""}
                   />
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Apakšvirsraksts(LV)"
                       {...register("lv.subtitle", { required: true })}
                       error={!!errors.lv?.subtitle}
                       helperText={errors.lv?.subtitle ? "subtitle is required" : ""}
                   />
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Apraksts(LV)"
                       multiline={true}
                       rows={8}
                       {...register("lv.description", { required: true })}
                       error={!!errors.lv?.description}
                       helperText={errors.lv?.description ? "description is required" : ""}
                   />
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Rezultāti(LV)"
                       {...register("lv.results", { required: true })}
                       error={!!errors.lv?.results}
                       helperText={errors.lv?.results ? "description is required" : ""}
                   />
               </Box>
               <Box px={4}>
                   <Typography variant={'h6'}>RU</Typography>
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Заголовок(RU)"
                       {...register("ru.title", { required: true })}
                       error={!!errors.ru?.title}
                       helperText={errors.ru?.title ? "title is required" : ""}
                   />
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Подзаголовок(RU)"
                       {...register("ru.subtitle", { required: true })}
                       error={!!errors.ru?.subtitle}
                       helperText={errors.ru?.subtitle ? "subtitle is required" : ""}
                   />
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Описание(RU)"
                       multiline={true}
                       rows={8}
                       {...register("ru.description", { required: true })}
                       error={!!errors.ru?.description}
                       helperText={errors.ru?.description ? "description is required" : ""}
                   />
                   <TextField
                       sx={{ mb: 2 }}
                       fullWidth
                       type="text"
                       label="Результат(RU)"
                       {...register("ru.results", { required: true })}
                       error={!!errors.ru?.results}
                       helperText={errors.ru?.results ? "description is required" : ""}
                   />
               </Box>
           </Box>
            <Button fullWidth type="submit" variant="contained" color="primary">
                {isSubmitting?<CircularProgress />:'Submit'}
            </Button>
        </Box>
    );
};

export default ProjectBaseForm;
