import {Grid, Hidden} from "@mui/material";
import GridPop from "../../Atoms/GridPop/GridPop";
import Pop1 from "../../../Assets/IMG_3274.jpeg";
import Pop2 from "../../../Assets/IMG_3272.jpeg";
import Pop3 from "../../../Assets/IMG_3278.jpeg";
import Pop4 from "../../../Assets/IMG_3098.jpg";
import Pop5 from "../../../Assets/IMG_3104.jpg";


import Pop6 from "../../../Assets/IMG_3100.jpg";
import Pop7 from "../../../Assets/IMG_3273.jpeg";
import React from "react";

const PopBanner = () => {

    return(
        <Grid height={'100%'} container item lg={6} xl={5}  spacing={2}>
            <Grid item md={6}><GridPop imgageUrl={Pop1} borderRadius={'80px'}/></Grid>
            <Grid item md={4}><GridPop imgageUrl={Pop2} borderRadius={'150px'}/></Grid>

            <Grid item md={3}><GridPop imgageUrl={Pop3} borderRadius={'100px'}/></Grid>
            <Grid item md={7}><GridPop imgageUrl={Pop4} borderRadius={'80px'}/></Grid>

           <Hidden lgDown>
               <Grid item md={4}><GridPop imgageUrl={Pop5} borderRadius={'150px'}/></Grid>
               <Grid item md={3}><GridPop imgageUrl={Pop6} borderRadius={'100px'}/></Grid>
               <Grid item md={3}><GridPop imgageUrl={Pop7} borderRadius={'100px'}/></Grid>
           </Hidden>
        </Grid>
    )
}

export default PopBanner;