import {Button, Grid, TextField, Typography, useTheme} from "@mui/material";
import * as React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

interface FormData {
    name: string;
    email: string;
    message: string;

}
interface ContactMeFormProps{
    sent?:()=>boolean
    handleClick:()=>void
}
const ContactMeForm = ({sent,handleClick}:ContactMeFormProps)=>{
    const theme = useTheme();
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormData>();
    const {t} = useTranslation();
    const onSubmit = async (data:FormData) => {
        try {
            const response = await fetch("https://formspree.io/f/xyyrybob", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                handleClick()
                reset()
                sent && sent()
                // console.log("Form successfully sent");
                // Handle success scenario
            } else {
                console.error("Formspree submission error:", response.statusText);
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };
    return(
        <>
            <Grid
                component={'form'}
                onSubmit={handleSubmit(onSubmit)}
                container
                item
                xs={12}
                mt={'2rem'}
            >
                <Grid item xs={12} md={12} lg={10} mb={'2rem'}>
                    <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>{t('label_form_name')}:</Typography>
                    <TextField
                        {...register("name", { required:t('label_validation_name') })}
                        type={'text'}
                        color={'primary'}

                        fullWidth
                        variant="outlined"
                        sx={{backgroundColor: theme.palette.background.default, input: { color:theme.palette.primary.main}, // styles the input text
                            label: { color:theme.palette.primary.main }, // styles the label text
                         }}
                        error={!!errors.name}
                        // helperText={<>{errors.name && errors.name.message}</>}
                    />
                    {errors.name && <Typography variant={'caption'}>{errors.name.message}</Typography>}
                </Grid>

                {/* Email Field */}
                <Grid item  xs={12} md={12} lg={10} mb={'2rem'}>
                    <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>{t('label_form_email')}:</Typography>
                    <TextField
                        {...register("email", {
                            required: t('label_validation_email'),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t('label_validation_wrong_email')
                            }
                        })}
                        type={'email'}
                        fullWidth
                        variant="outlined"
                        sx={{backgroundColor: theme.palette.background.default, input: { color:theme.palette.primary.main}, // styles the input text
                            label: { color:theme.palette.primary.main }, }}
                        error={!!errors.email}
                    />
                    {errors.email && <Typography variant={'caption'}>{errors.email.message}</Typography>}

                </Grid>

                {/*Message Field*/}
                <Grid item  xs={12} md={12} lg={10} mb={'2rem'}>
                    <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>{t('label_form_message')}</Typography>
                    <TextField
                        {...register("message", { required: t('label_validation_message') })}
                        fullWidth
                        // multiline
                        rows={8}
                        variant={'outlined'}
                        sx={{backgroundColor: theme.palette.background.default, input: { color:theme.palette.primary.main}, // styles the input text
                            label: { color:theme.palette.primary.main },  '& .MuiFormHelperText-root': {
                                backgroundColor: 'transparent'
                            }}}
                        error={!!errors.message}
                        // helperText={<>{errors.message && errors.message.message}</>}

                    />
                    {errors.message && <Typography variant={'caption'}>{errors.message.message}</Typography>}
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12} md={8} lg={10} mb={'2rem'}>
                    <Button size={'large'} variant={'contained'} type={'submit'} color={'primary'} sx={{ borderRadius:'50px', backgroundImage: 'linear-gradient(90deg, #b16cea, #ffa14e)', display: 'inline' }}>
                        <Typography color={"primary"} variant={'h6'}>{t('label_from_submit')}</Typography>
                    </Button>
                </Grid>
            </Grid>
            {/*</Grid>*/}
        </>
    )
}

export default ContactMeForm;