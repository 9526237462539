import React from "react";
import {Collapse, Grid, IconButton, Typography} from '@mui/material';
import Testimonial from "../Molecules/Tesitmonial/Testimonial";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Testimonial as TestimonialModel} from "../../../Models/Models";
import {useTranslation} from "react-i18next";
interface TestimonialProps {
  testimonials:TestimonialModel[]
}
const ClientTestimonials: React.FC<TestimonialProps> = ({testimonials}) => {
  const [collapsed, setCollapsed] = React.useState(true);
    const {t} = useTranslation();
  const handleChange = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <Grid id={'Testimonials'} container spacing={3} justifyContent="center">
          <Grid  mt={{
              xs: 5,
              sm: 5,
              md: 5,
              lg: 10,
              xl: 10,

          }} mb={{
              xs: 5,
              sm: 5,
              md: 5,
              lg: 5,
              xl: 5,
          }} item={true} xs={12} justifyContent="center">
            <Typography variant="h1" textAlign={'center'}  gutterBottom>
                {t('label_testimonials_headline').split(' ')[0]}
              <span>
                 <Typography sx={{
                   ml: 2,
                   backgroundImage: 'linear-gradient(45deg, #EC6186, #FFA34D)',
                   '-webkit-background-clip': 'text',
                   color: 'transparent',
                   display: 'inline-block',
                 }} variant="h1"
                             fontWeight={'bold'}>{t('label_testimonials_headline').split(' ')[1]}</Typography>
              </span>
            </Typography>

          </Grid>
        <Grid container item xs={12} md={10} lg={8} xl={8} justifyContent="center" spacing={3}>
            {testimonials.map((testimonial, index) => (
                <Grid item key={index} xs={12} md={6} lg={5} xl={5}>
                    <Testimonial  image={testimonial.avatar}  testimonialText={testimonial.testimonialText} rating={testimonial.rating} clientName={testimonial.clientName} clientTitle={testimonial.clientTitle}/>
                </Grid>
            )).slice(0, 2)}
          {testimonials.map((testimonial, index) => (

                <Grid display={collapsed ? 'block':'none'} item key={index} xs={12} md={6} lg={5} xl={5}>
                  <Collapse in={collapsed}>
                  <Testimonial image={testimonial.avatar} testimonialText={testimonial.testimonialText} rating={testimonial.rating} clientName={testimonial.clientName} clientTitle={testimonial.clientTitle}/>
                  </Collapse>
                </Grid>
          )).slice(2,testimonials.length)}
        </Grid>


      <Grid container justifyContent="center" display={testimonials.length <2 ? 'none': ''} item xs={12}>
        <IconButton color={'secondary'} onClick={handleChange}>{collapsed?<ExpandLessIcon/>:<ExpandMoreIcon/>}</IconButton>
      </Grid>

    </Grid>
  );
};

export default ClientTestimonials;
