import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Company } from "../../../../Models/Models";

interface CompaniesSectionProps {
  companies: Company[];
}
const CompaniesSection = ({ companies }: CompaniesSectionProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container item xs={12} id={"Clients"}>
        <Grid
          mt={{
            xs: 5,
            sm: 5,
            md: 5,
            lg: 10,
            xl: 10,
          }}
          mb={{
            xs: 5,
            sm: 5,
            md: 5,
            lg: 10,
            xl: 10,
          }}
          container
          justifyContent={"center"}
          item
          xs={12}
          py={5}
        >
          <Typography
            mr={2}
            width={"fit-content"}
            textAlign={"center"}
            variant="h1"
            fontWeight={"bold"}
          >
            {t("label_companies_headline").split(" ")[0]}
          </Typography>
          <Typography
            textAlign={"center"}
            variant="h1"
            fontWeight={"bold"}
            sx={{
              backgroundImage: "linear-gradient(90deg,#B16CEA, #FFA34D)",
              "-webkit-background-clip": "text",
              color: "transparent",
              display: "inline-block",
            }}
          >
            {t("label_companies_headline").split(" ")[1]}
          </Typography>
        </Grid>

        <Grid container item xs={12} justifyContent={"center"}>
          <Grid
            container
            item
            xs={12}
            md={10}
            lg={8}
            xl={10}
            justifyContent={"center"}
            spacing={5}
            alignItems={"start"}
          >
            {companies.map((company, index) => (
              <Grid item key={index} xs={12} md={4} lg={3} xl={2}>
                <a
                    target={"_blank"}
                  href={company.link}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <img
                    style={{ width: "100%", height: 50, objectFit: "contain" }}
                    alt={company.name}
                    src={company.logo}
                  />
                  <Typography variant={"h6"} textAlign={"center"}>
                    {company.name}
                  </Typography>
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompaniesSection;
