import { Box } from "@mui/material";
import React from "react";
interface GridPopProps{
    imgageUrl:string;
    borderRadius?:string;
}
const GridPop = ({imgageUrl,borderRadius }:GridPopProps) => {
    return (
       <Box
           width={'100%'}
           height={'100%'}
           borderRadius={borderRadius?borderRadius:'10px'}
           sx={{
                backgroundImage:`url(${imgageUrl})`,
                backgroundSize:'cover',
                backgroundPosition:'center',
                backgroundRepeat:'no-repeat',
               }}
       >

       </Box>
    );
}

export default GridPop;