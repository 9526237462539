import { Grid, Typography} from "@mui/material";
import ServiceCard from "../../Organs/ServiceCard/ServiceCard";
import {useTranslation} from "react-i18next";
import React from "react";
import {useInView} from "react-intersection-observer";
import {animated, useSpring} from "react-spring";
import IMG1 from "../../../../Dot/Assets/DALL·E 2023-12-29 12.39.38 - A professional videographer filming a TikTok video. The scene includes the videographer with a camera on a tripod, filming a creative setup de.png";
import IMG2 from "../../../../Dot/Assets/DALL·E 2023-12-29 12.36.53 - A marketing professional discussing a collaboration with a TikTok influencer over a video call. The influencer is in a creative, trendy enviro.png";
import IMG3 from "../../../../Dot/Assets/DALL·E 2023-12-29 12.38.08 - A diverse group of marketing professionals in a modern office brainstorming TikTok content. The scene includes a large table with digital devi.png";
import IMG4 from "../../../../Dot/Assets/DALL·E 2024-01-11 12.03.53 - A digital marketing professional working on paid Facebook_Instagram and Google ads. The scene includes the professional at a desk with multiple comput.png";

const OurServices= () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        // threshold: 0.5, // Adjust as needed
    });

    const flyInAnimation = useSpring({
        from: { transform: "translate3d(0,50px,0)", opacity: 0 },
        to: {
            transform: inView ? "translate3d(0,0px,0)" : "translate3d(0,50px,0)",
            opacity: inView ? 1 : 0,
        },
        config: { duration: 500 },
    });
    const AnimatedBox = animated(
        Grid
    );
const {t} = useTranslation();
    return(
       <Grid id={'Services'} container  ref={ref} >
            <Grid mt={{
                xs: 5,
                sm: 5,
                md: 5,
                lg: 10,
                xl: 10,

            }} mb={{
                xs: 5,
                sm: 5,
                md: 5,
                lg: 5,
                xl: 5,
            }}  item>

                 <Typography sx={{
                     ml: 2,
                     backgroundImage: 'linear-gradient(45deg, #EC6186, #FFA34D)',
                     '-webkit-background-clip': 'text',
                     color: 'transparent',
                     display: 'inline-block',
                 }} variant="h1"
                             fontWeight={'bold'}>{t('label_services_headline')}</Typography>

            </Grid>
          <Grid container spacing={8}>
              <AnimatedBox spacing={2}  style={flyInAnimation} id="par mums" container direction={"row-reverse"} justifyContent={'space-around'} alignItems={'center'}item>
                  <ServiceCard index={1} alt={t('label_services_title_1')} title={t('label_services_title_1')} text={t('label_services_text_1')} imgSrc={IMG3} price={t('label_services_price1')}/>
              </AnimatedBox>
              <AnimatedBox spacing={2} style={flyInAnimation} ref={ref} id="par mums" container direction={"row"} justifyContent={'space-around'}  alignItems={'center'} item>
                  <ServiceCard index={1} alt={t('label_services_title_2')} title={t('label_services_title_2')} text={t('label_services_text_2')} imgSrc={IMG2}  price={t('label_services_price2')}/>
              </AnimatedBox>
              <AnimatedBox spacing={2} style={flyInAnimation} ref={ref} id="par mums" container direction={"row-reverse"} justifyContent={'space-around'}  alignItems={'center'} item>
                  <ServiceCard index={1} alt={t('label_services_title_3')} title={t('label_services_title_3')} text={t('label_services_text_3')} imgSrc={IMG1}  price={t('label_services_price3')}/>
              </AnimatedBox>
              <AnimatedBox spacing={2} style={flyInAnimation} ref={ref} id="par mums" container direction={"row"} justifyContent={'space-around'}  alignItems={'center'} item>
                  <ServiceCard index={1} alt={t('label_services_title_4')} title={t('label_services_title_4')} text={t('label_services_text_4')} imgSrc={IMG4}  price={t('label_services_price_4')}/>
              </AnimatedBox>
          </Grid>
        </Grid>
    )
}
export default OurServices;