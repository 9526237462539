import {Button, Grid, Typography} from "@mui/material";
import FaqList from "../../Molecules/FaqList/FaqList";
import * as React from "react";
import {useTranslation} from "react-i18next";

interface FaqSectionProps {

}

const FaqSection = ({}: FaqSectionProps) => {
    const {t} = useTranslation();
    return (
        <Grid id={'FAQ'} mt={{
            xs: 5,
            sm: 5,
            md: 5,
            lg: 10,
            xl: 10,

        }} mb={{
            xs: 5,
            sm: 5,
            md: 5,
            lg: 5,
            xl: 5,
        }} container justifyContent={'center'}>
                <Grid container item xs={12} sm={12} lg={6}>
                    <Grid item xs={12}>
                        <Typography variant={'h2'}  sx={{
                            backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            display: 'inline-block'
                        }}>{t('label_nav_faq')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>{t('label_hero_subtitle')}</Typography>
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <FaqList/>
            </Grid>
        </Grid>
    )
}

export default FaqSection;