import {Box, Button, TextField, Typography} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {Company} from "../../../Models/Models";
import {useApiContext} from "../../../Contexts/ApiContext";
import React from "react";

const ClientsForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<Company>();
    const {addClient,clientError} = useApiContext();
    const onSubmit: SubmitHandler<Company> = (data:Company,event) => {
        addClient(data)
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {clientError && <Typography variant={'h6'}>Try Again, something went wrong</Typography>}
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="text"
                label="Klienta nosaukums"
                {...register("name", { required: true })}
                error={!!errors.name}
                helperText={errors.name ? "name is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="text"
                label="Links uz klienta"
                {...register("link", { required: true })}
                error={!!errors.link}
                helperText={errors.link ? "link is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Logo url"
                type="text"
                {...register("logo", { required: true})}
                error={!!errors.logo}
                helperText={errors.logo ? "logo is required" : ""}
            />
            <Button fullWidth type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </Box>
    );
}

export default ClientsForm;