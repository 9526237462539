import {
  Divider,
  Grid,
  Hidden,
  IconButton,
  Link,
  ListItem,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BoltIcon from "@mui/icons-material/Bolt";
import CopyrightIcon from "@mui/icons-material/Copyright";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import useScrollTo from "../../../Hooks/useScollToElement/useScrollToElement";
import { useTranslation } from "react-i18next";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Instagram from "@mui/icons-material/Instagram";
const FooterSection = () => {
  const [activeSection, setActiveSection] = useState(null);
  function scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      const yOffset = -100; // Adjust this value as needed for the offset
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      let currentSection = null;

      const sections = [
        "Profile",
        "Work Experience",
        "Education",
        "Hard Skills",
        "Languages",
        "Links",
        "Contact",
      ];

      sections.forEach((section) => {
        const element = document.getElementById(section);
        const rect = element && element.getBoundingClientRect();
        if (rect && rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          // You can adjust this to better suit your needs
          currentSection = section;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollTo = useScrollTo();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const { t } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Hidden lgDown>
        <Grid container item xs={12} my={"4rem"}>
          <Grid item xs={12} alignSelf={"center"} my={"2rem"}>
            <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Grid item xs={"auto"}>
              <Typography variant={"h4"}>Picasso</Typography>
            </Grid>
            <Grid item xs={"auto"} direction={"row"} width={"fit-content"}>
              <Tabs
                sx={{ width: "fit-content" }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  onClick={() => {
                    scrollTo("Services");
                  }}
                  label={t("label_nav_services")}
                />
                <Tab
                  onClick={() => {
                    scrollTo("Clients");
                  }}
                  label={t("label_nav_clients")}
                />
                <Tab
                  onClick={() => {
                    scrollTo("Projects");
                  }}
                  label={t("label_nav_projects")}
                />
                <Tab
                  onClick={() => {
                    scrollTo("Testimonials");
                  }}
                  label={t("label_nav_testimonials")}
                />
                <Tab
                  onClick={() => {
                    scrollTo("Contact");
                  }}
                  label={t("label_nav_contact")}
                />
                <Tab
                  onClick={() => {
                    scrollTo("FAQ");
                  }}
                  label={t("label_nav_faq")}
                />
              </Tabs>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            direction={"row"}
            mt={"4rem"}
            justifyContent={"space-between"}
          >
            <Grid container item sm={4} direction={"row"} alignItems={"center"}>
              <Typography marginLeft={"0.5rem"} variant={"caption"}>
                Copyright © 2024 SIA Picasso Marketing. All Rights Reserved.
              </Typography>
            </Grid>
            <Grid container item direction={"row"} width={"fit-content"}>
              <IconButton href="https://wa.me/37126977520" target={"_blank"}>
                <WhatsApp
                  sx={{ color: theme.palette.primary.main }}
                  fontSize={"large"}
                />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/picasso.marketing/"
                target={"_blank"}
              >
                <Instagram
                  sx={{ color: theme.palette.primary.main }}
                  fontSize={"large"}
                />
              </IconButton>
              <IconButton
                href="https://www.tiktok.com/@picasso.marketing?_t=8iAi0VjUw52&_r=1"
                target={"_blank"}
              >
                {/*<Icon>{TikTok}</Icon>*/}
                <SvgIcon
                  sx={{ color: theme.palette.primary.main }}
                  fontSize={"large"}
                >
                  <svg
                    fill="#FFFFFF"
                    viewBox="0 0 512 512"
                    id="icons"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                  </svg>
                </SvgIcon>
              </IconButton>
            </Grid>
            <Grid
              container
              item
              sm={12}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography marginLeft={"0.5rem"} variant={"caption"}>
                {" "}
                Implemented By{" "}
                <Link
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  onClick={() => window.open("http://primeit.lv")}
                  target={"_blank"}
                >
                  PrimeIT
                </Link>
                , Romans Jefremovs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid container item xs={12} mb={"2rem"}>
          <Grid item xs={12} alignSelf={"center"} my={"2rem"}>
            <Divider sx={{ bgcolor: theme.palette.primary.main }} />
          </Grid>
          <Grid container item xs={12} justifyContent={"space-between"}>
            <Grid item xs={"auto"}>
              <Typography variant={"h6"}>Picasso</Typography>
            </Grid>
            <Grid xs={"auto"} item direction={"row"} width={"fit-content"}>
              <IconButton href="https://wa.me/37122837006" target={"_blank"}>
                <WhatsApp
                  sx={{ color: theme.palette.primary.main }}
                  fontSize={"large"}
                />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/picasso.marketing/"
                target={"_blank"}
              >
                <Instagram
                  sx={{ color: theme.palette.primary.main }}
                  fontSize={"large"}
                />
              </IconButton>
              <IconButton
                href="https://www.tiktok.com/@picasso.marketing?_t=8iAi0VjUw52&_r=1"
                target={"_blank"}
              >
                {/*<Icon>{TikTok}</Icon>*/}
                <SvgIcon
                  sx={{ color: theme.palette.primary.main }}
                  fontSize={"large"}
                >
                  <svg
                    fill="#FFFFFF"
                    viewBox="0 0 512 512"
                    id="icons"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                  </svg>
                </SvgIcon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            direction={"row"}
            mt={"4rem"}
            justifyContent={"space-between"}
          >
            <Grid container item sm={8} direction={"row"} alignItems={"center"}>
              <Typography marginLeft={"0.5rem"} variant={"caption"}>
                Copyright © 2024 SIA Picasso Marketing. All Rights Reserved.
              </Typography>
              <Typography marginLeft={"0.5rem"} variant={"caption"}>
                {" "}
                Implemented By{" "}
                <Link
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={() => window.open("http://primeit.lv")}
                    target={"_blank"}
                >
                  PrimeIT
                </Link>
                , <Link  sx={{ "&:hover": { cursor: "pointer" } }}
                         onClick={() => window.open("http://romans-jefremovs.com")}
                         target={"_blank"}>
                Romans Jefremovs
              </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
export default FooterSection;