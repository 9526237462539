export const resources = {
    "LV": {
        translation:  {
            "label_companies_headline": "Mūsu klienti",
            "label_contact_headline": "Sazinieties ar mums\n",
            "label_contact_text": "Ja Jums ir vēlme sadarboties, atstājiet mums ziņu, izmantojot formu. Mēs sazināsimies ar jums pēc iespējas ātrāk. Piedāvājam organizēt arī tikšanos, kurā apspriedīsim Jūsu vēlmes un iespējas.",
            "label_dark": "Tumšs",
            "label_faq_answer1": "Jā, apkalpojam arī atsevišķas sociālās platformas (piemēram, tikai Instagram)",
            "label_faq_answer2": "Ilgtermiņa līguma slēgšana uzreiz nav obligāta, sadarbības sākumā pakalpojums var tikt sniegts uz priekšapmaksas pamata.",
            "label_faq_answer3": "Jā, ja uzņēmumam nav izveidots konts sociālo tīklu platformā(s), mēs to izveidojam un noformējam profilu.",
            "label_faq_answer4": "Šo informāciju mēs iegūstam no mērķauditorijas analīzes, industrijai atbilstošiem trendiem un uzņēmuma pamatmērķiem. Veidojam dažādu saturu, lai auditoriju maksimāli ieinteresētu un noturētu tās uzmanību uz Jūsu uzņēmumu vēlamajā platformā.",
            "label_faq_questions1": "1.\tVai iespējams izvēlēties tikai vienas sociālās platformas uzturēšanu?",
            "label_faq_questions2": "2.\tVai obligāti jāslēdz pakalpojuma izpildes ilgtermiņa līgums?",
            "label_faq_questions3": "3.\tJa manam uzņēmuma nav konts sociālo tīklu platformā, Jūs to izveidosiet?",
            "label_faq_questions4": "4.\tKā Jūs nosakāt, kādu saturu un cik bieži jāpublicē?",
            "label_form_email": "Jūsu E-pasts",
            "label_form_message": "Pastāstiet, kas Jūs interesē!",
            "label_form_name": "Jūsu vārds",
            "label_form_success": "Ziņojums nosūtīts! Paldies, ka sazinājāties ar mums.",
            "label_from_submit": "Iesniegt",
            "label_hero": "Jūs esat uz pareizā ceļa! ",
            "label_hero_button": "Apskatīt projektus",
            "label_hero_subtitle": "Mēs zinām, kā parādīt Jūsu zīmolu klientam. ",
            "label_hire_us": "Uzzināt vairāk",
            "label_light": "Gaišs",
            "label_nav_clients": "Klienti",
            "label_nav_contact": "Kontakti",
            "label_nav_faq": "Bieži Uzdotie Jautājumi",
            "label_nav_projects": "Projekti",
            "label_nav_services": "Pakalpojumi",
            "label_nav_testimonials": "Atsauksmes",
            "label_portfolio_headline": "Apskatiet mūsu projektus",
            "label_see_more": "Vairāk",
            "label_services_headline": "Mūsu Pakalpojumi",
            "label_services_price_4": "No 500€ līdz 1000€",
            "label_services_price1": "No 500€ līdz 1000€",
            "label_services_price2": "No 75€ līdz 450€",
            "label_services_price3": "No 800€ līdz 1500€",
            "label_services_text_1": " Veidojam scenārijus, filmējam, montējam video un uzturam TikTok kontu. Veidojam ierakstu kalendāru, viss izveidotais materiāls paliek Jūsu īpašumā. Cena ir atkarīga no atrunātā satura daudzuma.",
            "label_services_text_2": "Instagram/ Facebook organiskā satura veidošana no mūsu veidotiem foto un video, kontu uzturēšana. Veidojam ierakstu kalendāru, viss izveidotais saturs paliek Jūsu īpašumā. Cena ir atkarīga no atrunātā satura daudzuma.",
            "label_services_text_3": "Veidojam saturu un uzturam TikTok, Instagram, Facebook kontus. Veidojam ierakstu kalendārus (Instagram/Facebook un atsevišķi TikTok). Viss izveidotais materiāls paliek Jūsu īpašumā. Cena ir atkarīga no atrunātā satura daudzuma.",
            "label_services_text_4": "Facebook/Instagram un Google maksas reklāmu izveide, iestatīšana, optimizēšana. Tās virzam gan uz tiešām pārdošanām (mazākiem produktiem), gan, lai ģenerētu pieteikumus konsultācijai (lielākiem produktiem). Ja vēlaties vismaz dubultot sava uzņēmuma rezultātus online vidē - noteikti spēsim Jums palīdzēt.",
            "label_services_title_1": "TikTok Satura Izveide un Stratēģija",
            "label_services_title_2": "Instagram/Facebook satura izveide un stratēģija",
            "label_services_title_3": "Instagram/ Facebook/ TikTok satura izveide un stratēģija",
            "label_services_title_4": "Maksas reklāmas",
            "label_talk": "Parunāsim!",
            "label_testimonials_headline": "Klientu Atsauksmes",
            "label_validation_email": "Nepieciešams E-pasts",
            "label_validation_message": "Ziņojums ir obligāts",
            "label_validation_name": "Vārds ir obligāts",
            "label_validation_wrong_email": "Nepareizs e-pasta formāts",
            "test": "tests"
        },
    },
    "RU": {
        translation:  {
            "label_companies_headline": "Наши клиенты",
            "label_contact_headline": " Свяжитесь с нами",
            "label_contact_text": "Если вы хотите сотрудничать, оставьте нам сообщение, используя форму. Мы свяжемся с вами как можно скорее. Также предлагаем организовать встречу, на которой мы обсудим Ваши пожелания и возможности.",
            "label_dark": "Тёмный",
            "label_faq_answer1": "Да, мы предлагаем нужные вам услуги и для одной платформы (на пример, только Инстаграм)",
            "label_faq_answer2": "Долгосрочный договор сразу заключать не обязательно. На начальном этапе услуга может предоставляется на основании предоплаты.",
            "label_faq_answer3": "Если у вашей компании, нет странички в социальных сетях, мы откроем и оформим профиль с самого начала. ",
            "label_faq_answer4": "Количество рекламы и её содержание зависит от рода деятельности компании. Мы проводим анализ и предлагаем оптимальные варианты для Вас.",
            "label_faq_questions1": "1.\tВозможно ли выбрать услугу только для одной социальной платформы?",
            "label_faq_questions2": "2.\tОбязательно ли заключать долгосрочный договор на обслуживание?",
            "label_faq_questions3": "3.\tЕсли у моей компании нет странички в социальных сетях, создадите ли вы ее?",
            "label_faq_questions4": "4.\tКак вы определяете, какой контент публиковать и как часто?",
            "label_form_email": "Ваш электронный адрес",
            "label_form_message": "Расскажите нам, что вас интересует!",
            "label_form_name": "Ваше имя",
            "label_form_success": "Сообщение отправлено! Спасибо, что связались со нами.",
            "label_from_submit": "Отправить",
            "label_hero": "Вы на правильном пути!",
            "label_hero_button": "Смотреть проекты",
            "label_hero_subtitle": "Мы знаем, как представить ваш бренд покупателю.",
            "label_hire_us": "Узнать больше",
            "label_light": "Светлый",
            "label_nav_clients": "Клиенты",
            "label_nav_contact": "Контакты",
            "label_nav_faq": "Частые Вопросы",
            "label_nav_projects": "Проекты",
            "label_nav_services": "Услуги",
            "label_nav_testimonials": "Отзывы",
            "label_portfolio_headline": "Посмотрите наши проекты",
            "label_see_more": "Больше",
            "label_services_headline": "Наши Услуги",
            "label_services_price_4": "От 500€ до 1000€",
            "label_services_price1": "От 500€ до 1000€",
            "label_services_price2": "От 75€ до 450€.",
            "label_services_price3": "От 800€ до 1500€",
            "label_services_text_1": "Создаем сценарии, снимаем, монтируем видео и ведем аккаунт в TikTok. Создаем календарь публикаций, весь созданный материал остается вашей собственностью. Цена зависит от объема согласованного контента.",
            "label_services_text_2": "Создание органического контента для Instagram/Facebook из наших фото и видео, ведение аккаунтов. Создаем календарь публикаций, весь созданный контент остается вашей собственностью. Цена зависит от объема согласованного контента.",
            "label_services_text_3": "Создаем контент и ведем аккаунты в TikTok, Instagram, Facebook. Разрабатываем календари публикаций (отдельно для Instagram/Facebook и TikTok). Весь созданный материал остается вашей собственностью. Цена зависит от объема согласованного контента.",
            "label_services_text_4": "Создание, настройка и оптимизация платной рекламы в Facebook/Instagram и Google. Мы нацеливаем ее как на прямые продажи (для меньших продуктов), так и для генерации заявок на консультацию (для крупных продуктов). Если вы хотите хотя бы удвоить результаты вашего бизнеса в онлайн-среде - мы обязательно сможем вам помочь.",
            "label_services_title_1": "Создание Контента и Разработка Стратегии для TikTok",
            "label_services_title_2": "Создание контента и стратегия для Instagram/Facebook.",
            "label_services_title_3": "Создание контента и стратегия для Instagram/Facebook/ TikTok.",
            "label_services_title_4": "Платная реклама",
            "label_talk": "Поговорим!",
            "label_testimonials_headline": "Отзывы Клиентов",
            "label_validation_email": "Требуется электронный адрес",
            "label_validation_message": "Требуется сообщение",
            "label_validation_name": "Требуется имя",
            "label_validation_wrong_email": "Неверный формат электронной почты",
            "test": "тест"
        }
    }
}


// const resourcesa = {
//     RU: {
//         translation: {
//             "Welcome to React": "Welcome to React and react-i18next"
//         }
//     },
//     LV: {
//         translation: {
//             "Welcome to React": "Bienvenue à React et react-i18next"
//         }
//     }
// };
