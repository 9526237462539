import React from "react";

import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
     useTheme, Box, CardActions, Button,
} from "@mui/material";
import {Project} from "../../../Models/Models";
import {useTranslation} from "react-i18next";

interface ourProjectsProps {
  projects: Project[];
}

const OurProjects: React.FC<ourProjectsProps> = ({projects}:ourProjectsProps) => {
  const {t} = useTranslation();
const theme = useTheme()
  return (
    <Grid id={'Projects'} container justifyContent={'center'} spacing={5} >
        <Grid container mt={{
            xs: 5,
            sm: 5,
            md: 5,
            lg: 10,
            xl: 10,

        }} mb={{
            xs: 5,
            sm: 5,
            md: 5,
            lg: 5,
            xl: 5,
        }} item xs={12}>
            <Typography
                mr={2}
                width={'fit-content'}
            variant="h1"
            >{t('label_portfolio_headline').split(' ')[0]}</Typography>
          <Typography
              mr={2}
                width={'fit-content'}
            variant="h1"
            >{t('label_portfolio_headline').split(' ')[1]}</Typography>
          <Typography
              sx={{
                backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                display: 'inline-block'
              }}
                width={'fit-content'}
            variant="h1"
            >{t('label_portfolio_headline').split(' ')[2]}</Typography>
        </Grid>
      {projects.map((company, index) => (

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={3}
          key={index+company.imageUrl}
        >
          <Card
              key={index+company.imageUrl}
            sx={{
              border: "0px solid #ccc",
              boxShadow: "0 0 10px 0 rgba(0,0,0,0.3)", // Adjusted shadow for effect
              borderRadius: "20px",
              backgroundColor: theme.palette.warning.main, // Set background color to black
              height: '100%', // Make card height 100% of its container
              transition: "transform 0.15s ease-in-out", // Animation for hover
              "&:hover": {
               xs: {
                },
                  md: {
                      transform: "scale(1.05)",
                      cursor: 'pointer'// Scale up card on hover
                  }
              },
            }}
          >

            <CardMedia
              component="video"
              autoPlay
              playsInline
              loop
              muted
              poster={company.imageUrl}
              sx={{
                  objectFit: "cover",
                height: {
                    xs: 300,
                    sm: 300,
                    md: 300,
                    lg: 400,
                    xl: 400,
                },
                borderRadius: "20px 20px 0 0",
              }}
            >
               <source
                  src={company.videoUrl}
                  type="video/mp4"
              />
              </CardMedia>

              <CardContent  sx={{ textAlign: "left", color: theme.palette.primary.main, px:{
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 5,
                    xl: 5,
                }
                  }}>
                  <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography variant="h4" component="div" >
                          {company.title}
                      </Typography>

                  </Box>
              <Typography variant="subtitle1" color="gray">
                {company.subtitle}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {company.description}
              </Typography>

              <Typography variant="h6">
                  {company.results}
              </Typography>
            </CardContent>
              <CardActions sx={{p:2, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    <Button  onClick={()=>window.open(company.link)} sx={{borderRadius:'30px'}} variant={'contained'} color={'primary'}>{t('label_see_more')}</Button>
                  <Typography color={theme.palette.primary.main} variant="caption">
                      {company.date}
                  </Typography>
              </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default OurProjects;
