import {Box, Button, Grid, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import useScrollTo from "../../../Hooks/useScollToElement/useScrollToElement";

interface ServiceCardProps{
    imgSrc: string;
    alt: string;
    title: string;
    text: string;
    index: number;
    price: string;
}
const ServiceCard = ({imgSrc,text,title,index,alt,price }: ServiceCardProps) => {
        const {t} = useTranslation();
    const scrollTo = useScrollTo();
    return(
     <>
         <Grid  item xs={12} sm={12} md={12} lg={6} xl={4}>
                <Box height={{
                    xs: '200px',
                    sm: '300px',
                    md: '400px',
                    lg: '500px',
                    xl: '600px',
                }}

                     sx={{
                    backgroundImage: `url('${imgSrc}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px',
                }}>
                </Box>
         </Grid>
         <Grid spacing={4} container height={'fit-content'} justifyContent={{
             xs: 'center',
             sm: 'center',
             md: 'center',
             lg: 'flex-start',
             xl: 'flex-start',

         }}
               textAlign={{
                     xs: 'center',
                     sm: 'center',
                     md: 'center',
                     lg: 'left',
                     xl: 'left',
                }}
               item xs={12} md={12} lg={6} xl={6}>
             <Grid item xs={12} >
                 <Typography width={'100%'} variant={'h3'}>{title}</Typography>
             </Grid>
             <Grid item xs={12} md={10} lg={10}>
                 <Typography variant={'body1'}>{text}</Typography>
             </Grid>
             <Grid item xs={12} md={10} lg={10}>
                 <Typography fontWeight={'600'} variant={'body1'}>{price}</Typography>
             </Grid>
             <Grid item >
                 <Button onClick={()=>scrollTo('Contact')}  variant={'contained'} sx={{borderRadius:'40px',backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                     display: 'inline',}}   color={'primary'}> <Typography color={"secondary"} variant={'h6'}>{t('label_hire_us').toUpperCase()}</Typography></Button>
             </Grid>
         </Grid>
     </>
    )
}

export default ServiceCard;