import {useTheme} from "@mui/material";

const RotatedLineSpacer = () => {
        const theme = useTheme()
    return (
        <div className={theme.palette.mode ==='dark'?'rotated-div-light':'rotated-div-dark'}>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
                <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
                <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Develop</span>
            <span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Discover</span>
            {/*<span className={theme.palette.mode ==='dark'?'text-item-light':'text-item-dark'}>Design</span>*/}
        </div>
    )
}
export default RotatedLineSpacer;