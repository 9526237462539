import {Box, Button, Typography} from "@mui/material";
import {useApiContext} from "../../../Contexts/ApiContext";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import React, {useEffect, useState} from "react";
import ProjectBaseForm from "../../Form/ProjectForm/ProjectsForm";
import NestedTable from "../../Table/PortfolioTable/PortfolioTable";

const Projects = () => {
    const {projects,getAllProjects}= useApiContext()

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const model = {
        title: '',
        description: '',
        price: 0,
        eventDate: '',
        eventType: ''
    }

    useEffect(()=>{
        getAllProjects()
    },[])

    return (
        <Box  display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box width={'80%'}>
                <Box mb={5} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography alignSelf={'start'} variant={'h2'}>Projekti</Typography>
                    <Button onClick={handleOpen} sx={{width:'fit-content'}} variant={'contained'}>Pievienot Projektu</Button>
                </Box>
                {projects?.length !=0  && projects ? <NestedTable projects={projects}  />: <Typography variant={'h6'}>Nav Projektu</Typography>}
            </Box>
            <BasicModal width={1000} title={'Add Project'} open={open} handleOpen={handleOpen} handleClose={handleClose}>
                <ProjectBaseForm />
            </BasicModal>
        </Box>
    )
}
export default Projects