import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import * as React from "react";
import {Box, Typography} from "@mui/material";
interface ListeItemProps{
    question:string
    answer:string
}
const ListItem = ({ question, answer }:ListeItemProps) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return(
        <Box  sx={{width:'100%'}}>
            <ListItemButton onClick={handleClick}>
                <ListItemText>
                    <Typography variant={'body2'}>{question}</Typography>
                </ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box p={5}>
                    <Typography variant={'body1'}>{answer}</Typography>
                </Box>
            </Collapse>
        </Box>
    )
}

export default ListItem;