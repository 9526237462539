export enum STYLES_LIGHT {
    HEADING_FONT_FAMILY = "'Tektur', sans-serif",
    BODY_FONT_FAMILY =  "'Inter Tight', sans-serif",
    PRIMARY_COLOR = '#000000',
    SECONDARY_COLOR = '#FFFFFF',
    WARNING_COLOR = '#FFFFFF',
    SUCCESS_COLOR = '#4F4C44',
    INFO_COLOR = '#FFFFFF',
    BACKGROUND_COLOR = '#F0F2F5',
    PAPER_COLOR = '#FFFFFF',
    BUTTON_PRIMARY_COLOR = '#FFFFFF',
}
export enum STYLES_DARK {
    // HEADING_FONT_FAMILY ='"Bai Jamjuree", sans-serif',
    // BODY_FONT_FAMILY = '"Bai Jamjuree", sans-serif',
    HEADING_FONT_FAMILY = "'Tektur', sans-serif",
    BODY_FONT_FAMILY =  "'Inter Tight', sans-serif",
    PRIMARY_COLOR = '#FFFFFF',
    SECONDARY_COLOR = '#FFFFFF',
    // SECONDARY_COLOR = '#1C1C22',
    WARNING_COLOR = '#1C1C22',
    SUCCESS_COLOR = '#4F4C44',
    INFO_COLOR = '#FFFFFF',
    BACKGROUND_COLOR = '#161513',
    PAPER_COLOR = '#FFFFFF',
    BUTTON_PRIMARY_COLOR = '#FFFFFF',
}

