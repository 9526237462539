
import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import AppBarRender from "../Components/Organs/AppBar/AppBar";
import AppBarMob from "../Components/Organs/AppBar/AppBarMob";
import MenuDrawer from "../Components/Organs/MenuDrawer/MenuDrawer";
import Home from "./Home/Home";
import ContactModal from "../Components/Molecules/ContactModal";
import {useThemeContext} from "../../Contexts/ThemeContext";
import {useApiContext} from "../../Contexts/ApiContext";
import LoadingSpinner from "../Components/Molecules/LoadingSpinner/LoadingSpinner";
import { CircularProgress } from "@mui/material";
const HomePage = () => {
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openSnack, seSnacktOpen] = React.useState(false);
    const {theme, setLightTheme,setDarkTheme} = useThemeContext();
    const handleClick = () => {
        seSnacktOpen(true);
    };

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        seSnacktOpen(false);
    };

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    }
    // Function to toggle between light and dark theme
    const toggleDarkMode = () => {
        if(theme.palette.mode === 'dark'){
            setLightTheme();
        }else{
            setDarkTheme();
        }
        console.log(theme.palette.mode)
    }
    const {getAllTestimonials,getAllClients,getProjectsByLocale,isFetching} =useApiContext()
    useEffect(() => {
        getAllTestimonials();
        getAllClients();
        getProjectsByLocale('lv');
    }, [])

    if (isFetching) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }else return (
       <Box >
           <AppBarRender handleTheme={toggleDarkMode} themes={theme.palette.mode === 'light'? 'dark':'light'}  handleOpenModal={handleOpen}/>
           <AppBarMob  handleDrawerOpen={handleDrawerOpen}/>
           <MenuDrawer open={open} handleTheme={toggleDarkMode} themes={theme.palette.mode === 'light'? 'dark':'light'}  handleDrawerClose={handleDrawerClose}/>
           <Home handleOpenModal={handleOpen} openSnack={openSnack} handleClose={handleCloseSnack} handleClick={handleClick}/>
           <ContactModal handleClick={handleClick} open={openModal} handleClose={handleClose}/>
       </Box>
       
    );

};

export default HomePage;