import React from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from "@mui/material";

// Define a type for the component props
interface StaticRatingProps {
    rating: number;
}
const style = (mode: 'dark' | 'light') => ({
    '& .MuiRating-iconEmpty': {
        opacity: 0.25,
        color: mode === 'dark' ? '#ffffff' : '#000000',
    },
});

export default function StaticRating({ rating }: StaticRatingProps) {
    const { palette } = useTheme();
    return (
        <Rating
            name="read-only"
            value={rating}
            readOnly
            precision={0.5}
            icon={<StarIcon fontSize="inherit" />}
            emptyIcon={<StarIcon fontSize="inherit" />}
            sx={style(palette.mode)}
        />
    );
}
