import {
    Button, Grid, Hidden, Tab, Tabs, Typography, useTheme
} from "@mui/material";
import React from "react";
import useScrollTo from "../../../Hooks/useScollToElement/useScrollToElement";
import LanguageSwitcher from "../../Atoms/LanguageSwitcher/LanguageSwitcher";
import {useTranslation} from "react-i18next";
interface AppBarProps {
    handleTheme: () => void
    themes: 'dark'|'light'
    handleOpenModal:()=>void
}

const AppBarRender = ({handleTheme,themes,handleOpenModal}:AppBarProps) => {
    const scrollTo = useScrollTo()
    const [value, setValue] = React.useState(0);
    const theme= useTheme()
    const {t} = useTranslation();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return(
                         <Hidden lgDown >
                             <Grid  color={theme.palette.primary.main} container direction={'row'}  xs={12} item my={'2rem'} px={{
                                    xs: 2,
                                    sm: 0,
                                    md: 0,
                                    lg: 1,
                                    xl: 5,
                             }} >
                                 <Grid item sm={2}>
                                     <Typography variant={'h4'}>Picasso</Typography>
                                 </Grid>
                                 <Grid  item sm={7} container direction={ 'row'} justifyContent={'center'}>
                                     <Tabs sx={{width:'fit-content'}} value={value} onChange={handleChange} aria-label="basic tabs example">
                                         <Tab  onClick={()=>{scrollTo('Services')}}  label={t('label_nav_services')} />
                                         <Tab  onClick={()=>{scrollTo('Clients')}}  label={t('label_nav_clients')} />
                                         <Tab  onClick={()=>{scrollTo('Projects')}}  label={t('label_nav_projects')}/>
                                         <Tab  onClick={()=>{scrollTo('Testimonials')}} label={t('label_nav_testimonials')}/>
                                         <Tab  onClick={()=>{scrollTo('Contact')}}  label={t('label_nav_contact')}/>
                                         <Tab  onClick={()=>{scrollTo('FAQ')}} label={t('label_nav_faq')}/>
                                     </Tabs>
                                 </Grid>
                             <Grid container item sm={3} justifyContent={'center'} alignItems={'center'}>
                                     <Button onClick={handleOpenModal} variant={'contained'} sx={{borderRadius:'20px'}} color={"primary"}>{t('label_talk')}</Button>
                                     <Button  onClick={handleTheme} variant={'contained'} sx={{borderRadius:'20px', ml:2}}  color={"primary"}>{t(`label_${themes}`)}</Button>
                                     <LanguageSwitcher />
                             </Grid>
                             </Grid>
                         </Hidden>
    )
}
export default AppBarRender






