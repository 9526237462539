import Drawer from "@mui/material/Drawer";
import {
    Button,
    Divider,
    IconButton,
    styled, SvgIcon, Tab, Tabs,
    useTheme
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import useScrollTo from "../../../Hooks/useScollToElement/useScrollToElement";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Instagram from "@mui/icons-material/Instagram";
import LanguageSwitcher from "../../Atoms/LanguageSwitcher/LanguageSwitcher";
interface MenuDrawerProps {
    open: boolean
    handleDrawerClose: ()=>void;
    handleTheme: () => void
    themes: 'dark'|'light'
}

const MenuDrawer = ({open,handleDrawerClose,themes,handleTheme}:MenuDrawerProps) => {
    const [activeSection, setActiveSection] = useState<string|null>(null);
    const scrollTo = useScrollTo()
    useEffect(() => {
        const handleScroll = () => {
            let currentSection = null;

            const sections = [t('label_nav_services'), t('label_nav_clients'), t('label_nav_projects'),t('label_nav_testimonials'), t('label_nav_contact'), t('label_nav_faq')];

            sections.forEach(section => {
                const element = document.getElementById(section);
                const rect = element && element.getBoundingClientRect();
                if (rect && rect.top >= 0 && rect.top <= window.innerHeight / 2) { // You can adjust this to better suit your needs
                    currentSection = section;
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const [value, setValue] = React.useState(0);
    const theme= useTheme()
    const {t} = useTranslation();
    const sectionToTabIndex = {
        [t('label_nav_services')]: 0,
        [t('label_nav_clients')]: 1,
        [t('label_nav_projects')]: 2,
        [t('label_nav_testimonials')]: 3,
        [t('label_nav_contact')]: 4,
        [t('label_nav_faq')]: 5
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        const sectionName = Object.keys(sectionToTabIndex).find(key => sectionToTabIndex[key] === newValue);
        if (sectionName){
            setActiveSection(sectionName);
            scrollTo(sectionName)
        }
    };
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    }));
    const drawerWidth = 240;
  return(
      <Drawer
          sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                  width: drawerWidth,
              },
          }}
          variant="persistent"
          anchor="right"
          open={open}
      >
          <DrawerHeader  color={theme.palette.primary.main}>
              <IconButton color={'primary'} onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              <LanguageSwitcher/>
              <Button onClick={handleTheme} variant={'contained'} sx={{borderRadius:'20px'}}  color={"primary"}>{t(`label_${themes}`)}</Button>
          </DrawerHeader>
          <Divider />
          <Tabs  orientation={'vertical'} sx={{width:'100%'}} value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab  onClick={()=>{scrollTo('Services')}}  label={t('label_nav_services')} />
              <Tab  onClick={()=>{scrollTo('Clients')}}  label={t('label_nav_clients')} />
              <Tab  onClick={()=>{scrollTo('Projects')}}  label={t('label_nav_projects')}/>
              <Tab  onClick={()=>{scrollTo('Testimonials')}} label={t('label_nav_testimonials')}/>
              <Tab  onClick={()=>{scrollTo('Contact')}}  label={t('label_nav_contact')}/>
              <Tab  onClick={()=>{scrollTo('FAQ')}} label={t('label_nav_faq')}/>
          </Tabs>
          <Divider />
         <Box  sx={{
             alignSelf:'center',
             display: 'flex',
             alignItems: 'center',
             ...theme.mixins.toolbar,
             justifyContent: 'flex-start',
         }}>
             <IconButton href="https://wa.me/37122837006" target={'_blank'}>
                 <WhatsApp  sx={{color:theme.palette.primary.main}}   fontSize={'large'}/>
             </IconButton>
             <IconButton href="https://www.instagram.com/picasso.marketing/" target={'_blank'}>
                 <Instagram  sx={{color:theme.palette.primary.main}}   fontSize={'large'}/>
             </IconButton>
             <IconButton href="https://www.tiktok.com/@picasso.marketing?_t=8iAi0VjUw52&_r=1" target={'_blank'}>
                 <SvgIcon sx={{color:theme.palette.primary.main}} fontSize={'large'}>
                     <svg fill="#FFFFFF"  viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                 </SvgIcon>
             </IconButton>
         </Box>
      </Drawer>
  )
}
export default MenuDrawer