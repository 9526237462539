import {Avatar, Box, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import StaticRating from "../../Atoms/CustomizedRating/CustomizedRating";

interface TestimonialProps {
    clientName:string,
    clientTitle:string,
    testimonialText:string,
    rating:number,
    image:string | null | undefined
}
const Testimonial=({testimonialText,rating,clientName, clientTitle,image}:TestimonialProps)=> {
    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <StaticRating rating={rating}/>
                <Typography variant="body1">
                  "{testimonialText}"
                </Typography>
            </CardContent>
            <CardActions>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'start'} alignItems={'center'}>
                    {image && <Avatar sx={{width: 56, height: 56}} src={image}/>}
                    <Box ml={2} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                        <Typography fontWeight={600} variant="body2" component="div">
                            {clientName}
                        </Typography>
                        <Typography variant="body2">
                            {clientTitle}
                        </Typography>
                    </Box>
                </Box>
            </CardActions>
        </Card>
    )
}

export default Testimonial;