import React, {useEffect} from "react";
import {useApiContext} from "../../../Contexts/ApiContext";
import {Box, Button, Typography} from "@mui/material";
import GenericTable from "../../Table/Table";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import ClientsForm from "../../Form/ClientsForm/ClientsForm";
import Testimonial from "../../../Dot/Components/Molecules/Tesitmonial/Testimonial";
import TestimonialForm from "../../Form/TestimonialForm/TestimonialForm";

const Testiomonials = () => {
    const [open, setOpen] = React.useState(false);
    const {getAllTestimonials,testimonials,deleteTestimonial} = useApiContext()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const model = {
        title: '',
        description: '',
        price: 0,
        eventDate: '',
        eventType: ''
    }

    useEffect(()=>{
        getAllTestimonials()
    },[])
    return (
        <Box  display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box width={'80%'}>
                <Box mb={5} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography alignSelf={'start'} variant={'h2'}>Atsauksmes</Typography>
                    <Button onClick={handleOpen} sx={{width:'fit-content'}} variant={'contained'}>Pievienot Atsauksmi</Button>
                </Box>
                {testimonials?.length !=0  && testimonials ? <GenericTable data={testimonials}  onDelete={(item) => {deleteTestimonial(item.id)}} />: <Typography variant={'h6'}>Nav Atsauksmes</Typography>}
            </Box>
            <BasicModal title={'Pievienot Atsauksmi'} open={open} handleOpen={handleOpen} handleClose={handleClose}><TestimonialForm /></BasicModal>
        </Box>
    )
}

export default Testiomonials