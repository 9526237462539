import {Box, Button, CircularProgress, Typography} from "@mui/material";
import React, {useEffect} from "react";
import GenericTable from "../../Table/Table";
import BasicModal from "../../Modal/BasicModal/BasicModal";
import ClientsForm from "../../Form/ClientsForm/ClientsForm";
import {useApiContext} from "../../../Contexts/ApiContext";

const Clients = () => {
    const [open, setOpen] = React.useState(false);
    const {getAllClients,clients,deleteClient} = useApiContext()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const model = {
        title: '',
        description: '',
        price: 0,
        eventDate: '',
        eventType: ''
    }

useEffect(()=>{
    getAllClients()
},[])
    return (
        <Box  display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box width={'80%'}>
                <Box mb={5} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography alignSelf={'start'} variant={'h2'}>Klienti</Typography>
                    <Button onClick={handleOpen} sx={{width:'fit-content'}} variant={'contained'}>Pievienot Klientu</Button>
                </Box>
                {clients?.length !=0  && clients ? <GenericTable data={clients}  onDelete={(item) => {deleteClient(item.id)}} />: <Typography variant={'h6'}>Nav Klientu</Typography>}
            </Box>
            <BasicModal title={'Pievienot Klientu'} open={open} handleOpen={handleOpen} handleClose={handleClose}><ClientsForm /></BasicModal>
        </Box>
    )
}


export default Clients