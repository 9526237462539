import { Button, Grid, Hidden, Typography, useTheme} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import PopBanner from "../PopBanner/PopBanner";
import PopBannerSmall from "../PopBanner/PopBannerSmall";
import useScrollToElement from "../../../Hooks/useScollToElement/useScrollToElement";
interface ProfileSectionProps {
    handleOpenModal:()=>void
}
const ProfileSection = ({handleOpenModal}:ProfileSectionProps) => {
    const theme = useTheme()
    const {t} = useTranslation();
    const scrollTo=useScrollToElement();
    return(
        <Grid height={'100vh'} container item alignItems={'center'} justifyContent={'center'} mb={{
            xs: 5,
            sm: 5,
            md: 5,
            lg: 5,
            xl: 5,
        }} id={'Profile'}>
                    <Hidden lgDown>
                        <Grid container height={'75%'} justifyContent={'center'} alignItems={'center'}>
                            <Grid container item height={'fit-content'} xs={10} lg={5} >
                                <Grid item xs={12} lg={10} mb={2}>
                                    <Typography
                                        sx={{
                                            backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                                            '-webkit-background-clip': 'text',
                                            color: 'transparent',
                                            display: 'inline'
                                        }}
                                        variant={"h1"} >
                                        {t('label_hero')}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={10} mb={5}>
                                    <Grid item xs={12}>
                                        <Typography  color={theme.palette.primary.main}  variant={'body2'}>
                                            {t('label_hero_subtitle')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2} xs={8} xl={10}>
                                    <Grid item xs={8} lg={10} xl={7}>
                                        <Button fullWidth onClick={handleOpenModal} variant={'contained'} sx={{borderRadius:'40px',backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                                            display: 'inline'}} > <Typography color={"primary"} variant={'h6'}>{t('label_contact_headline')}</Typography></Button>
                                    </Grid>
                                    <Grid item xs={3} md={6} lg={9} xl={6}>
                                        <Button fullWidth onClick={()=>scrollTo('Projects')} variant={'outlined'} sx={{borderRadius:'40px'}}  color={"primary"}><Typography color={"primary"} variant={'h6'}>{t('label_hero_button')}</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <PopBanner/>
                        </Grid>
                    </Hidden>

                    <Hidden lgUp>
                        <PopBannerSmall/>
                        <Grid container  bgcolor={theme.palette.mode === 'dark'? 'rgba(0,0,0,0.5)':'rgba(255,255,255,0.5)'} item height={'120%'} direction={'column'}
                              position={'absolute'}
                              justifyContent={'center'}>
                            <Grid container position={'absolute'}  justifyContent={'center'} p={{
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 5,
                                xl: 5,

                            }}>
                                <Grid  container item xs={12}  mb={2} justifyContent={'center'}>
                                    <Typography
                                        sx={{
                                            backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                                            '-webkit-background-clip': 'text',
                                            color: 'transparent',
                                            display: 'inline-block'
                                        }}
                                        textAlign={'center'}
                                        variant={"h1"} >
                                        {t('label_hero')}
                                    </Typography>
                                </Grid>
                                <Grid item mb={2} xs={10}>
                                    <Typography textAlign={'center'}  color={theme.palette.primary.main}  variant={'body2'}>
                                        {t('label_hero_subtitle')}
                                    </Typography>
                                </Grid>
                                <Grid container item sm={12} direction={'row'} justifyContent={'center'}>
                                    <Button onClick={handleOpenModal} variant={'contained'} sx={{borderRadius:'40px',backgroundImage: 'linear-gradient(90deg,#B16CEA, #FFA34D)',
                                        display: 'inline',}}   color={'primary'}> <Typography color={"secondary"} variant={'h6'}>{t('label_contact_headline')}</Typography></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>

        </Grid>
    )
}
export default ProfileSection;