import {Hidden, Modal, Typography} from "@mui/material";
import ContactMeForm from "./ContactMeForm/ContactMeForm";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

interface ContactModalProps{
    open:boolean,
    handleClose:()=>void,
    handleClick:()=>void
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius:'30px',
    boxShadow: 24,
    p: 4,
};
const style2 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius:'30px',
    boxShadow: 24,
    p: 2,
};

const ContactModal = ({open, handleClose, handleClick}:ContactModalProps)=>{
    const {t} = useTranslation();
    const sent = ()=>{
    handleClose()
        return true
    }
    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
           sx={{border:'none'}}
        >
           <>
               <Hidden lgDown>
                   <Box sx={style}>
                       <Typography variant={'h3'}>{t('label_contact_headline')}</Typography>
                       <ContactMeForm handleClick={handleClick} sent={sent}/>
                   </Box>
               </Hidden>
               <Hidden lgUp>
                   <Box sx={style2}>
                       <Typography variant={'h4'}>{t('label_contact_headline')}</Typography>
                       <ContactMeForm handleClick={handleClick} sent={sent}/>
                   </Box>
               </Hidden>
           </>
        </Modal>
    )
}
export default ContactModal