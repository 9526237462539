import React, {createContext, ReactNode, useCallback, useContext, useState} from "react";
import {supabase} from "../supabaseClient";
import {
    Company,
    companyMock, Project,
    ProjectBase,
    ProjectResponse, projectsMock,
    ProjectTranslation,
    Testimonial,
    testimonialsMock
} from "../Models/Models";


interface ApiContextType {
    addProject: (projectBase:ProjectBase, ru:ProjectTranslation,lv:ProjectTranslation) => void;
    getProjectsByLocale: (locale:'ru'|'lv') => void;
    getAllProjects: () => void;
    projectError: null | Error;
    translationError: null | Error;
    isSubmitting: boolean;
    projects:ProjectResponse[];
    deleteProject: (projectId:number) => void;
    isDeleting: boolean;
    deleteError: null | Error;
    isFetching: boolean;
    fetchError: null | Error;
    getAllClients: () => void;
    addClient: (company:Company) => void;
    clients:Company[];
    clientError: null | Error;
    deleteClient: (clientId:number) => void;
    getAllTestimonials: () => void;
    addTestimonial: (testimonial:Testimonial) => void;
    testimonials:Testimonial[];
    testimonialsError: null | Error;
    deleteTestimonial: (testimonialId:number) => void;
    portfolio:Project[];
}

const ApiContext = createContext<ApiContextType | null>(null);

interface ThemeContextProviderProps {
    children: ReactNode;

}


export const ApiProvider = ({ children }: ThemeContextProviderProps) => {
    const [projectError, setProjectError] = useState< null | Error>(null);
    const [clientError, setClientError] = useState< null | Error>(null);
    const [testimonialsError, setTestimonialsError] = useState< null | Error>(null);
    const [translationError, setTranslationError] = useState< null | Error>(null);
    const [deleteError, setDeleteError] = useState< null | Error>(null);
    const [fetchError, setFetchError] = useState< null | Error>(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [projects, setProjects] = useState<ProjectResponse[]>([]);
    const [portfolio, setPortfolio] = useState<Project[]>(projectsMock);
    const [testimonials, setTestimonials] = useState<Testimonial[]>(testimonialsMock);
    const [clients, setClients] = useState<Company[]>(companyMock);
    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }
    const addProject = useCallback(async (projectBase:ProjectBase, ru:ProjectTranslation,lv:ProjectTranslation) => {
        setIsSubmitting(true);
        setProjectError(null);
      try {
          const { data, error } = await supabase
              .from('projects')
              .insert([
                  { date: projectBase.date,videoUrl:projectBase.videoUrl, imageUrl: projectBase.imageUrl, link: projectBase.link},
              ])
              .select()
          if (error) {
              throw error
          }
        const project_id:number = data[0].id;
          const ruData = await addTranslations(project_id, ru);
          if (!ruData) {
                throw new Error('Translation error occurred.');
          }
            const lvData = await addTranslations(project_id, lv);
          if (!lvData) {
              throw new Error('Translation error occurred.');
          }

          if (lvData && ruData) {
              return {project: data[0], ru: ruData[0], lv: lvData[0]};
          }else{
              throw new Error('Translation error occurred.');
          }

      }catch (error) {
            if (isError(error)) {
                setProjectError(error);
            } else {
                setProjectError(new Error('An unknown error occurred.'));
            }

      }finally {
            setIsSubmitting(false);
      }



    },[]);

    const addTranslations = useCallback(async (project_id:number, project_translation:ProjectTranslation) => {
        setIsSubmitting(true);
        setTranslationError(null);
        try {

            const { data, error } = await supabase
                .from('project_translations')
                .insert([
                    { ...project_translation, project_id: project_id},
                ])
                .select()
            if (error) {
                throw error
            }
            return data;
        }catch (error) {
            if (isError(error)) {
                setTranslationError(error);
            } else {
                setTranslationError(new Error('An unknown error occurred.'));
            }
        }finally {
            setIsSubmitting(false);
        }
    },[]);

    const getAllProjects = useCallback(async () => {
        setIsFetching(true);
        setFetchError(null)
        try {
            const { data, error } = await supabase
                .from('projects')
                .select(`
                id,
                date,
                imageUrl,
                videoUrl,
                link,
                project_translations (
                    title,
                    subtitle,
                    description,
                    results,
                    language
                )
            `);

            if (error) {
                throw error
            }
            const projectsData = data as ProjectResponse[];
            setProjects(projectsData);
        }catch (e) {
            if (isError(e)) {
                setFetchError(e);
            } else {
                setFetchError(new Error('An unknown error occurred.'));
            }
        }finally {
            setIsFetching(false);
        }
    },[]);

    const deleteProject = async (projectId:number) => {
        setIsDeleting(true);
        setDeleteError(null);

        try {
            // First, delete the project translations
            const translationDeleteResponse = await supabase
                .from('project_translations')
                .delete()
                .match({ project_id: projectId });

            if (translationDeleteResponse.error) {
                throw translationDeleteResponse.error;
            }

            // Then, delete the project
            const projectDeleteResponse = await supabase
                .from('projects')
                .delete()
                .match({ id: projectId });

            if (projectDeleteResponse.error) {
                throw projectDeleteResponse.error;
            }

            // Optionally, update the local state to reflect the deletion
            setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));

        } catch (error) {
            if (isError(error)) {
                setDeleteError(error);
            } else {
                setDeleteError(new Error('An unknown error occurred during the deletion process.'));
            }
        } finally {
            setIsDeleting(false);
        }
    };
    const getProjectsByLocale = useCallback(async (locale:'ru'|'lv') => {
        setIsFetching(true);
        setFetchError(null)
        try {
            const { data, error } = await supabase
                .from('projects')
                .select(`
                date,
                imageUrl,
                videoUrl,
                link,
                project_translations (
                    title,
                    subtitle,
                    description,
                    results
                )
            `)
                .eq('project_translations.language', locale);
            if (error) {
                throw error
            }
            const projectData:Project[] = data.map((project:any) => {
                return{
                    date: project.date,
                    imageUrl: project.imageUrl,
                    videoUrl: project.videoUrl,
                    link: project.link,
                    title: project.project_translations[0].title,
                    subtitle: project.project_translations[0].subtitle,
                    description: project.project_translations[0].description,
                    results: project.project_translations[0].results,
                    language:project.project_translations[0].language
                }
            })

            if (projectData) {
                console.log(projectData)
                setPortfolio(projectData);
            }
        }catch (e) {
            if (isError(e)) {
                setFetchError(e);
            } else {
                setFetchError(new Error('An unknown error occurred.'));
            }
        }finally {
            setIsFetching(false);
        }
    },[]);



    // Clients
    const addClient = useCallback(async (company:Company) => {
        setIsSubmitting(true);
        setClientError(null);
        try {

            const { data, error } = await supabase
                .from('companies')
                .insert([
                    { name:company.name, logo: company.logo, link: company.link},
                ])
                .select()
            if (error) {
                throw error
            }
            setClients(prevClients => [...prevClients,data[0]])
              return data;
        }catch (e) {
            if (isError(e)) {
                setClientError(e);
            } else {
                setClientError(new Error('An unknown error occurred.'));
            }
        }finally {
            setIsSubmitting(false);
        }
    },[]);

    const getAllClients = useCallback(async () => {
        setIsFetching(true);
        setFetchError(null)
        try {
            const { data, error } = await supabase
                .from('companies')
                .select(`
                id,
                name,
                logo,
                link
            `);

            if (error) {
                throw error
            }
            const clientsData = data as Company[];
            setClients(clientsData);
        }catch (e) {
            if (isError(e)) {
                setFetchError(e);
            } else {
                setFetchError(new Error('An unknown error occurred.'));
            }
        }finally {
            setIsFetching(false);
        }
    },[]);

    const deleteClient = async (clientId:number) => {
        setIsDeleting(true);
        setDeleteError(null);

        try {
            // First, delete the project translations
            const translationDeleteResponse = await supabase
                .from('companies')
                .delete()
                .match({ id: clientId });

            if (translationDeleteResponse.error) {
                throw translationDeleteResponse.error;
            }

            // Optionally, update the local state to reflect the deletion
            setClients(prevClients => prevClients.filter(client => client.id !== clientId));

        } catch (error) {
            if (isError(error)) {
                setDeleteError(error);
            } else {
                setDeleteError(new Error('An unknown error occurred during the deletion process.'));
            }
        } finally {
            setIsDeleting(false);
        }
    }



    //Testimonials

    const addTestimonial = useCallback(async (testimonial:Testimonial) => {
        setIsSubmitting(true);
        setTestimonialsError(null);
        try {

            const { data, error } = await supabase
                .from('testimonials')
                .insert([
                    { clientName:testimonial.clientName, clientTitle: testimonial.clientTitle, avatar: testimonial.avatar, testimonialText: testimonial.testimonialText, rating: testimonial.rating}
                ])
                .select()
            if (error) {
                throw error
            }
            setTestimonials(prevTestimonials => [...prevTestimonials,data[0]])
            return data;
        }catch (e) {
            if (isError(e)) {
                setTestimonialsError(e);
            } else {
                setTestimonialsError(new Error('An unknown error occurred.'));
            }
        }finally {
            setIsSubmitting(false);
        }
    },[]);

    const getAllTestimonials = useCallback(async () => {
        setIsFetching(true);
        setFetchError(null)
        try {
            const { data, error } = await supabase
                .from('testimonials')
                .select(`
                id,
                clientName,
                clientTitle,
                avatar,
                testimonialText,
                rating
            `);

            if (error) {
                throw error
            }
            const testimonialsData = data as Testimonial[];
            setTestimonials(testimonialsData);
        }catch (e) {
            if (isError(e)) {
                setFetchError(e);
            } else {
                setFetchError(new Error('An unknown error occurred.'));
            }
        }finally {
            setIsFetching(false);
        }
    },[]);

    const deleteTestimonial = async (testimonialId:number) => {
        setIsDeleting(true);
        setDeleteError(null);

        try {
            // First, delete the project translations
            const translationDeleteResponse = await supabase
                .from('testimonials')
                .delete()
                .match({ id: testimonialId });

            if (translationDeleteResponse.error) {
                throw translationDeleteResponse.error;
            }

            // Optionally, update the local state to reflect the deletion
            setTestimonials(prevTestimonials => prevTestimonials.filter(testimonial => testimonial.id !== testimonialId));

        } catch (error) {
            if (isError(error)) {
                setDeleteError(error);
            } else {
                setDeleteError(new Error('An unknown error occurred during the deletion process.'));
            }
        } finally {
            setIsDeleting(false);
        }
    }
    return (
        <ApiContext.Provider value={{
            addProject,
            projectError,
            translationError,
            isSubmitting,
            projects,
            getAllProjects,
            isFetching,
            fetchError,
            deleteProject,
            isDeleting,
            deleteError,
            getAllClients,
            addClient,
            clients,
            clientError,
            deleteClient,
            getAllTestimonials,
            addTestimonial,
            testimonials,
            testimonialsError,
            deleteTestimonial,
            getProjectsByLocale,
            portfolio
        }}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApiContext = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useThemeContext must be used within an ThemeContextProvider');
    }
    return context;
};
