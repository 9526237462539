import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    IconButton,
    Button,
    Tooltip, CircularProgress
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {ProjectResponse} from "../../../Models/Models";
import {useApiContext} from "../../../Contexts/ApiContext";

const Row = ({ project }: { project:ProjectResponse}) => {
    const [open, setOpen] = useState(false);
    const{ isDeleting,deleteProject}=useApiContext()
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{project.id}</TableCell>
                <TableCell>{project.date}</TableCell>
                <TableCell style={{ maxWidth: '200px' }}> {/* Adjust the maxWidth as needed */}
                    <Tooltip title={project.imageUrl}>
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {project.imageUrl}
                        </div>
                    </Tooltip>
                </TableCell>


                <TableCell style={{ maxWidth: '200px' }}> {/* Adjust the maxWidth as needed */}
                    <Tooltip title={project.videoUrl}>
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {project.videoUrl}
                        </div>
                    </Tooltip>
                </TableCell>
                <TableCell style={{ maxWidth: '200px' }}> {/* Adjust the maxWidth as needed */}
                    <Tooltip title={project.link}>
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {project.link}
                        </div>
                    </Tooltip>
                </TableCell>
                <TableCell><Button onClick={() => deleteProject(project.id)}>{isDeleting?<CircularProgress/>:'Delete'}</Button></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="translations">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Language</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Subtitle</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Results</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {project.project_translations.map((translation,index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{translation.language}</TableCell>
                                        <TableCell>{translation.title}</TableCell>
                                        <TableCell>{translation.subtitle}</TableCell>
                                        <TableCell>{translation.description}</TableCell>
                                        <TableCell>{translation.results}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
interface NestedTableProps {
    projects:ProjectResponse[]
}
const NestedTable = ({ projects} :NestedTableProps) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Image URL</TableCell>
                        <TableCell>Video URL</TableCell>
                        <TableCell>Link</TableCell>
                        <TableCell>Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects.map((project) => (
                        <Row key={project.id} project={project} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NestedTable;
