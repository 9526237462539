import React, {useEffect} from 'react';
import ProtectedRoute from "./Dash/Protected/ProtectedRoute";
import Dashboard from "./Dash/Dashboard";
import Login from "./Dash/Login/Login";
import HomePage from "./Dot/Pages/HomePage";
import NotFound from "./Dot/Pages/NotFound";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {useThemeContext} from "./Contexts/ThemeContext";
import {Box, ThemeProvider} from "@mui/material";
import {AuthProvider} from "./Contexts/Auth";
import CssBaseline from "@mui/material/CssBaseline";
import Clients from "./Dash/ChildrenComponents/Clients/Clients";
import Projects from "./Dash/ChildrenComponents/Projects/Projects";
import Testiomonials from "./Dash/ChildrenComponents/Testimonials/Testiomonials";
import {ApiProvider} from "./Contexts/ApiContext";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin-dashboard",
    element: (
        <ProtectedRoute>
          <Dashboard/>
        </ProtectedRoute>
    ),
    children: [
      { path: "portfolio", element: <ProtectedRoute><Projects /></ProtectedRoute> },
      { path: "clients", element: <ProtectedRoute><Clients /></ProtectedRoute> },
      { path: "testimonials", element: <ProtectedRoute><Testiomonials /></ProtectedRoute> },
    ],
  },
  {
    path: "*",
    element: <NotFound/>,
  },
]);
function App() {
  const {theme} = useThemeContext();
  useEffect(() => {
  }, [theme]);
  return (
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ApiProvider>
            <Box width={'100%'} height={'100%'} bgcolor={theme.palette.background.default}>
              <RouterProvider router={router} />
            </Box>
          </ApiProvider>
        </AuthProvider>
        <CssBaseline />
      </ThemeProvider>
  );
}

export default App;
