import {Box, Button, TextField, Typography} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {Testimonial} from "../../../Models/Models";
import {useApiContext} from "../../../Contexts/ApiContext";
import React from "react";

const TestimonialForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<Testimonial>();
    const {addTestimonial,testimonialsError} = useApiContext();
    const onSubmit: SubmitHandler<Testimonial> = (data:Testimonial,event) => {
        addTestimonial(data)
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {testimonialsError && <Typography variant={'h6'}>Try Again, something went wrong</Typography>}
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="text"
                label="Klienta vārds"
                {...register("clientName", { required: true })}
                error={!!errors.clientName}
                helperText={errors.clientName ? "client Name is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="text"
                label="Klienta amats"
                {...register("clientTitle", { required: true })}
                error={!!errors.clientTitle}
                helperText={errors.clientTitle ? "client Title is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Atsauksme"
                multiline={true}
                type="text"
                {...register("testimonialText", { required: true})}
                error={!!errors.testimonialText}
                helperText={errors.testimonialText ? "testimonial Text is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Logo vai foto url"
                type="text"
                {...register("avatar", )}
                error={!!errors.avatar}
                helperText={errors.avatar ? "avatar is required" : ""}
            />
            <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Ratings 1-5"
                type="number"
                {...register("rating", { required: true,min:1,max:5})}
                error={!!errors.rating}
                helperText={errors.rating ? "rating is required" : ""}
            />
            <Button fullWidth type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </Box>
    );
}

export default TestimonialForm;