// LanguageSwitcher.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import {Box, Typography, useTheme} from "@mui/material";
import {useApiContext} from "../../../../Contexts/ApiContext";

const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const {getProjectsByLocale} = useApiContext()
const theme = useTheme()
    const changeLanguage = (language: 'ru'|'lv') => {
        i18n.changeLanguage(language.toUpperCase());
        getProjectsByLocale(language);
    };

    return (
        <Box  color={theme.palette.primary.main} display={'flex'} flexDirection={'row'} gap={1} ml={2}>
            <Typography
                // component="span"
                variant={'subtitle1'}
                onClick={() => changeLanguage('lv')}
                sx={{
                    cursor: 'pointer',
                    textDecoration: currentLanguage === 'LV' ? 'underline' : 'none'
                }}
            >
                LV
            </Typography>
            <Typography
                // component="span"
                variant={'subtitle1'}
                onClick={() => changeLanguage('ru')}
                sx={{
                    cursor: 'pointer',
                    textDecoration: currentLanguage === 'RU' ? 'underline' : 'none'
                }}
            >
                RU
            </Typography>
        </Box>
    );
}

export default LanguageSwitcher;
